/* global $ */

class App {
    constructor() {
        this.menuMoving = false;
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initStickyMenu();
        this.initToasts($('.toast'));
        this.initForms();
        this.initFontAwesome();
        this.initMatrixBlock();
        $('body').css('opacity', 1);
        console.log('App initialized');

        $(".navbar-toggler").click(function () {
            $('body').toggleClass("menuOpen");
        });
        // Smooth scrolling using jQuery easing
        // $('a.nav-link[href*="#"]:not([href="#"])').click(function () {
        //     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        //         var target = $(this.hash);
        //         target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        //         if (target.length) {
        //             $('html, body').animate({
        //                 scrollTop: (target.offset().top - 80)
        //             }, 2000);
        //             return false;
        //         }
        //     }
        // });
        $('a.nav-link[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: (target.offset().top - 80)
                    }, 20);
                    return false;
                }
            }
        });

        // Closes responsive menu when a scroll trigger link is clicked
        $('.head-section .nav-link').click(function () {
            $('.navbar-collapse').toggleClass('show');
            $('.icon-bar').toggleClass('collapsed');
        });

        $('.page a.nav-link[href*="#"]:not([href="#"])').each(function () {
            var oldUrl = $(this).attr("href"); // Get current url
            var newUrl = oldUrl.replace("/", ""); // Create new url
            $(this).attr("href", newUrl); // Set herf value
        });
    }

    disableLogger() {
        if (window.Globals.env == 'production') {
            console.log = function () { };
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 200) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }


    initFontAwesome() {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms() {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"), (i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }

    initToasts(elems) {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems) {
        import(/* webpackChunkName: "bootstrap" */ 'bootstrap').then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }

}

export default App;